<template>
    <div>
      <eden-table-actions :title="title" @search="search">
        <template slot="actions">
          <el-button
            type="plain"
            icon="eden-icon-upload"
            :disabled="loading || !pageData.length"
            @click="exportCustomers"
          >
            Export
          </el-button>
        </template>
      </eden-table-actions>
      <template v-if="loading">Loading...</template>
      <template v-else>
        <el-table :data="pageData">
          <el-table-column >
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'business.profile',
                  params: { id: scope.row.id },
                  query: {company_name: scope.row.name}
                }"
              >
                <span class="font-sm text-primary">
                  {{ scope.row.name }}</span
                ></router-link
              >
              <span class="font-xsm">{{
                scope.row.contact_person_email_address
              }}</span>
            </template>
          </el-table-column>
          <el-table-column >
            <template slot="header">
              <span class="eden-table-header"> Subscription Groups </span>
            </template>
            <template slot-scope="scope">
              <p class="text-grey-primary">{{ scope.row.subscriptions.length ? scope.row.subscriptions.length : '--' }}</p>
            </template>
          </el-table-column>

          <el-table-column>
            <template slot="header">
              <span class="eden-table-header"> Location Area </span>
            </template>
            <template slot-scope="scope">
              <p>{{ scope.row.location_area_name }}</p>
            </template>
          </el-table-column>
          <el-table-column v-if="allowAccessFor(['superadmin'])" width="60">
            <template slot-scope="scope">
              <el-dropdown @command="command" class="more">
                <span class="el-dropdown-link">
                  <i class="eden-icon-more" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="eden-icon-delete"
                    :command="{
                      action: 'delete',
                      index: scope.$index,
                      customer: scope.row,
                    }"
                    >Delete customer</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :pageSize="per_page"
          :current-page.sync="page"
        />
      </template>
      <customer-corporate-export
        :show.sync="exporting"
        :data-time="pageDataTime"
      />
      <customer-corporate-delete
        :show.sync="showCustomerCorporateDelete"
        :customer-id="customer.id"
        :customer-name="customer.company_name"
        @success="updateCustomers"
      />
    </div>
  </template>
  
  <script>
  import CustomerCorporateExport from "./Actions/CustomerCorporateExport.vue";
  import CustomerCorporateDelete from "./Actions/CustomerCorporateDelete";
  
  import business from "@/requests/business";
  import * as actions from "@/store/action-types";
  
  export default {
    components: {
      CustomerCorporateDelete,
      CustomerCorporateExport,
    },
    name: "CustomersCorporate",
    data() {
      return {
        loading: false,
        page: 1,
        pageData: [],
        showPagination: true,
        exporting: false,
        customer: {},
        showCustomerCorporateDelete: false,
        pageDataTime: "",
      };
    },
    computed: {
      customers() {
        return this.$store.getters.corporate_accounts;
      },
      from() {
        return this.customers.pages[this.page].from;
      },
      to() {
        return this.customers.pages[this.page].to;
      },
      total() {
        return this.customers.total;
      },
      per_page(){
        return this.customers.per_page;
      },
      title() {
        const total = this.total;
        const result = this.pageData.length;
        return `${this.showPagination ? total || 0 : result} Corporate Customers`;
      },
    },
    watch: {
      page() {
        const pageFetched = !!this.customers.pages[this.page];
        if (pageFetched) {
          this.setPageData();
        } else {
          this.getCustomers();
        }
      },
    },
    created() {
      this.getCustomers();
    },
    methods: {
      getCustomers() {
        this.loading = true;
        this.$store
          .dispatch(actions.GET_CORPORATE_ACCOUNT, this.page)
          .then(() => {
            this.setPageData();
            this.loading = false;
            this.pageDataTime = new Date();
          })
          .catch((error) => {
            this.loading = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      },
      search(query) {
        if (query === "") {
          this.setPageData();
          return;
        }
  
        this.loading = true;
        business
          .search(query)
          .then((response) => {
            this.pageData = response.data.data.data;
            this.showPagination = false;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      },
      setPageData() {
        this.pageData = this.customers.pages[this.page].data;
        this.showPagination = true;
      },
      exportCustomers() {
        this.exporting = true;
      },
      command(command) {
        const { index, customer } = command;
        this.customer = { index, ...customer };
  
        if (command.action === "delete") {
          this.showCustomerCorporateDelete = true;
        }
      },
      updateCustomers() {
        this.pageData.splice(this.customer.index, 1);
        this.setTotal = this.setTotal - 1;
      },
    },
  };
  </script>
  