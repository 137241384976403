import axios from "axios";

export default {
  reminder() {
    return axios.post("test_sending_subscription_renewal");
  },

  charge(time) {
    return time === "am"
      ? axios.post("test_charging_subscription_customers")
      : axios.post("test_charging_subscription_customers_by_twelve_noon ");
  },
};
