<template>
    <div class="home">
        <eden-page-header :title="'Eden for Business'">
            <template slot="actions">
                <div class="page--head-actions is-flex is-flex-wrap align-center">
                    <el-dropdown v-if="allowAccessFor(['admin', 'gardener_pro'])" :placement="'bottom'" @command="command">
                        <!-- <el-button type="plain">
                            Actions
                            <i class="eden-icon-arrow-down"></i>
                        </el-button> -->
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reminder">Subscription Reminders</el-dropdown-item>
                            <el-dropdown-item command="charge">Charge Customers</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <template v-for="(type, i) in customerTypes">
                        <el-button v-if="tab === type.label && allowAccessFor(type.access)" :key="i" type="primary"
                            @click="addCustomer()">Add Customer</el-button>
                    </template>
                </div>
            </template>
        </eden-page-header>

        <section>
            <eden-page-stats :title="'Overview'" :stats="stats" :show-periods="true" :disabled="true" />
            <corporate-accounts></corporate-accounts>
        </section>


        <customers-subscription-reminder :show.sync="showCustomersSubscriptionReminder" />
        <customers-subscription-charge :show.sync="showCustomersSubscriptionCharge" />
    </div>
</template>
  
<script>
import CorporateAccounts from "../../components/Business/CorporateAccounts.vue";
import CustomersSubscriptionReminder from "@/components/Customers/CustomersSubscriptionReminder";
import CustomersSubscriptionCharge from "@/components/Customers/CustomersSubscriptionCharge";

export default {
    name: "Customers",
    components: {
        CustomersSubscriptionCharge,
        CustomersSubscriptionReminder,
        CorporateAccounts,
    },
    data() {
        return {
            customerTypes: [
                {
                    label: "individual",
                    access: "all",
                },
                {
                    label: "corporate",
                    access: ["superadmin", "admin", "gardener_pro", "operations"],
                },
                {
                    label: "onetime",
                    access: "all",
                },
            ],
            tab: "corporate",
            showCustomersSubscriptionReminder: false,
            showCustomersSubscriptionCharge: false,
        };
    },
    computed: {
        stats() {
            const summary = this.$store.getters.customers_summary || {};
            return [
                {
                    label: "Total customers",
                    figure: this.formatFigure(summary.total_customers),
                },
                {
                    label: "New subscribers today",
                    figure: this.formatFigure(summary.new_subscribers_today),
                },
                {
                    label: "Total subscribers",
                    figure: this.formatFigure(summary.total_subscribers),
                },
                {
                    label: "Paused customers",
                    figure: this.formatFigure(summary.total_paused),
                },
                {
                    label: "Churned customers",
                    figure: this.formatFigure(summary.total_churned),
                },
                {
                    label: "Prospects",
                    figure: this.formatFigure(summary.total_prospects),
                },
            ];
        },
    },
    created() {
        let { tab } = this.$route.query;
        this.tab = tab ? tab : "individual";
    },
    methods: {
        updateRoute(tab) {
            const currentTab = this.$route.query.tab;

            if (currentTab === this.tab) {
                return false;
            }
            this.$router.push({
                name: "business.index",
                query: { tab: tab.name },
            });
        },
        command(command) {
            if (command === "reminder") {
                this.showCustomersSubscriptionReminder = true;
            } else if (command === "charge") {
                this.showCustomersSubscriptionCharge = true;
            }
        },
        addCustomer() {
            this.$router.push({ name: `business.add-account` });
        },
    },
};
</script>
  