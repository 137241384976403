<template>
  <el-dialog
    title="Subscription Reminder"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>
          You are about to send reminder to customers about their subscription
          expiring 3 days
        </p>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="shouldShow = false">Cancel</el-button>
      <el-button type="primary" :loading="sending" @click="sendReminder"
        >Send</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customers from "@/requests/customers/index";

export default {
  name: "CustomersSubscriptionReminder",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    sendReminder() {
      this.sending = true;
      customers
        .reminder()
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.sending = false;
            this.closeEvent();
          } else {
            this.sending = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.sending = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss"></style>
