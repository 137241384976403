var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.search}},[_c('template',{slot:"actions"},[_c('el-button',{attrs:{"type":"plain","icon":"eden-icon-upload","disabled":_vm.loading || !_vm.pageData.length},on:{"click":_vm.exportCustomers}},[_vm._v(" Export ")])],1)],2),(_vm.loading)?[_vm._v("Loading...")]:[_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'business.profile',
              params: { id: scope.row.id },
              query: {company_name: scope.row.name}
            }}},[_c('span',{staticClass:"font-sm text-primary"},[_vm._v(" "+_vm._s(scope.row.name))])]),_c('span',{staticClass:"font-xsm"},[_vm._v(_vm._s(scope.row.contact_person_email_address))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Name")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"text-grey-primary"},[_vm._v(_vm._s(scope.row.subscriptions.length ? scope.row.subscriptions.length : '--'))])]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Subscription Groups ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.location_area_name))])]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Location Area ")])])],2),(_vm.allowAccessFor(['superadmin']))?_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                  index: scope.$index,
                  customer: scope.row,
                }}},[_vm._v("Delete customer")])],1)],1)]}}],null,false,3675360663)}):_vm._e()],1),(_vm.showPagination)?_c('eden-pagination',{attrs:{"from":_vm.from,"to":_vm.to,"total":_vm.total,"pageSize":_vm.per_page,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],_c('customer-corporate-export',{attrs:{"show":_vm.exporting,"data-time":_vm.pageDataTime},on:{"update:show":function($event){_vm.exporting=$event}}}),_c('customer-corporate-delete',{attrs:{"show":_vm.showCustomerCorporateDelete,"customer-id":_vm.customer.id,"customer-name":_vm.customer.company_name},on:{"update:show":function($event){_vm.showCustomerCorporateDelete=$event},"success":_vm.updateCustomers}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }