<template>
  <div>
    <el-dialog
      title="Export Corporate Customers"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields" :max="5">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="
                      key === 'company_name' ||
                      key === 'contact_person' ||
                      key === 'contact_email'
                    "
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
export default {
  name: "CustomerCorporateExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        company_name: "Company Name",
        contact_email: "Email",
        contact_person: "Contact Person",
        employees: "Employees",
        status: "Status",
        amount: "Amount",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["company_name", "contact_person", "contact_email"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["company_name", "contact_person", "contact_email"];
      this.shouldShow = false;
    },

    getExportData() {
      this.exporting = true;
      corporate
        .export()
        .then((response) => {
          this.formatExportData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },

    formatExportData(data) {
      let updated = this.formatDateAndTime(this.dataTime, "do m, y");
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Corporate Customers \nDownloaded at - ${updated}\nExported at - ${date}`;
      let headers = {
        sn: "S/N",
      };
      let companyData = [];
      this.form.fields.forEach((key) => (headers[key] = this.fields[key]));
      companyData = data.map((company, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "company_name":
              export_object[key] = this.formatName(company.company_name);
              break;
            case "contact_email":
              export_object[key] = company.contact_person_email_address;
              break;
            case "contact_person":
              export_object[key] = this.formatName(
                `${company.contact_person_first_name} ${company.contact_person_last_name}`
              );
              break;
            case "employees":
              export_object[key] = company.employee_count;
              break;
            case "status":
              export_object[key] = this.formatText(company.customer_status);
              break;
            case "amount":
              export_object[key] = company.subscription_amount || 0;
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: companyData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
