<template>
  <el-dialog
    title="Subscription Charge"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>What kind of charge you want to perform?</p>
      </el-col>
    </el-row>
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="" prop="charge_time" :rules="validateField()">
            <el-radio-group
              v-model="form.charge_time"
              class="eden-switch-radio small"
            >
              <el-radio-button label="am">12:00AM</el-radio-button>
              <el-radio-button label="pm">12:00PM</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="shouldShow = false">Cancel</el-button>
      <el-button type="primary" :loading="charging" @click="chargeCustomers"
        >Charge</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customers from "@/requests/customers/index";

export default {
  name: "CustomersSubscriptionCharge",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        charge_time: "am",
      },
      charging: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    chargeCustomers() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.charging = true;
        customers
          .charge(this.form.charge_time)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.charging = false;
              this.closeEvent();
            } else {
              this.charging = false;
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.charging = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss"></style>
